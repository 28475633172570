import React, { Component } from "react";
import PPSSelect from "../ui/src/Pages/pps-select";
import PPSBilling from "../ui/src/Pages/pps-billing";
import PPSBillingACH from "../ui/src/Pages/pps-billing-ach";
import PPSSelectACH from "../ui/src/Pages/pps-select-ach";
import { Cancel, Reject, Successful, TokenInvalid } from "./AppConstants";
import { CountryMap, getCreditCardsByAbbr } from "../ui/src/Utility/utils";
import config from "../config";
import PPSError from "../ui/src/Pages/pps-error";
import { ApricotLoader } from "../ui/src/Pages/elements";
import { findIndex } from "lodash";
import Api from "../service/Api";

const cb = window.cb;
let defaultCountry = "US";
let defaultState = "";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rejectCounter: 0,
      hasPaypal: false,
      paymentResp: null,
      returnUrl: null,
      elapsedTime: null,
      userPaymentMethod: null,
      paymentTokenGlobal: this.getQueryParam("token"),
      pagenameFlow: this.getQueryParam("pagenameflow"),
      actionFlow: this.getQueryParam("action"),
      googleResponse: null,
      googleWidgetId: null,
      appLogger: window.cb.core.utils.Logger.getLogger("CB-WPC-App"),

      page: null,
      errors: null,
      sessionTimeOutUrl: null,
      blockerNeeded: null,
      defaultEmptyAddress: null,
    };

    this.getPaymentConfig = this.getPaymentConfig.bind(this);
    this.onErrorAfterLitle = this.onErrorAfterLitle.bind(this);
    this.submitAfterLitle = this.submitAfterLitle.bind(this);
    this.submitToVantiv = this.submitToVantiv.bind(this);
    this.onTimeoutAfterLitle = this.onTimeoutAfterLitle(this);
    this.googleCallback = this.googleCallback.bind(this);
  }

  async componentDidMount() {
    var actionFlow = this.state.actionFlow;
    this.state.appLogger.info("action flow:" + actionFlow);

    if (!actionFlow) {
      this.getPaymentConfig();
    }
    if (actionFlow === "sessionTimeout") {
      var rawReturnUrl = this.getQueryParam("returnUrl");
      var encodedReturnUrl = encodeURI(rawReturnUrl);
      let newUrl = new URL(encodedReturnUrl);
      let protocol = newUrl.protocol;
      if (protocol === "https:" || protocol === "http:") {
        this.setState({ sessionTimeOutUrl: encodedReturnUrl });
      } else {
        const errors = {
          errorTitle: "Payment Error",
          errorMessage: "Invalid Return URL",
          errors: [],
        };
        this.setState({ error: errors, blockerNeeded: null });
      }
    }

    if (actionFlow === "processPaypal") {
      var paymentToken = this.getQueryParam("paymentToken");
      var cancel = this.getQueryParam("cancel");
      var returnUrl = this.getQueryParam("returnUrl");

      var component = this;
      var payload = { paymentToken: paymentToken, methodOfPayment: "PP" };
      if (cancel && cancel === "true") {
        window.location = returnUrl + "?token=" + paymentToken + "&status=1";
      } else {
        component.setState({ blockerNeeded: true });
        try {
          const response = await Api.postPayPalPayment(payload);
          console.log(response);
          window.location = `${returnUrl}?token=${paymentToken}&status=0`;
        } catch (error) {
          console.log(error);
          component.state.appLogger.error(error);
          const errors = {
            errorTitle: "Payment Error",
            errorMessage:
              "Please call Customer Service at 866-630-9305 for information regarding this order. We apologize for any inconvenience.",
            errors: [],
          };
          component.setState({ error: errors, blockerNeeded: null });
        }
      }
    }
  }

  getQueryParam(param) {
    var result = window.location.search.match(
      new RegExp("(\\?|&)" + param + "(\\[\\])?=([^&]*)")
    );
    return result ? result[3] : false;
  }

  selectPageOnCancel() {
    window.location =
      this.state.paymentResp.returnUrl +
      "?token=" +
      this.state.paymentTokenGlobal +
      "&status=" +
      Cancel;
  }

  selectPageOnSubmit(value) {
    var component = this;
    var paymentMethod = value;
    var paymentInfo = component.state.paymentResp;
    if (paymentMethod === null || paymentMethod === undefined)
      paymentMethod = "cards";
    if (paymentMethod === "cards") {
      component.setState({ page: "billing" });
    }

    if (paymentMethod === "echeck") {
      //check billing address
      if (this.state.paymentResp.billingInfo.address.country !== "US") {
        //for non US address, default address to be blank.
        this.setState({ defaultEmptyAddress: "true" });
      } else {
        this.setState({ defaultEmptyAddress: null });
      }
      component.setState({ page: "billing-ach" });
    }

    if (paymentMethod === "paypal") {
      this.setState({ blockerNeeded: true });

      let ppsUrl = window.REACT_APP_wpcUrl;
      if (window.REACT_APP_build_env !== "prod") {
        ppsUrl =
          "https://" +
          window.REACT_APP_build_env +
          ".pps-nonprod.collegeboard.org";
      }

      var paypalPayload = {
        paymentToken: component.state.paymentTokenGlobal,
        methodOfPayment: "PP",
        returnUrl:
          ppsUrl +
          "/?action=processPaypal&returnUrl=" +
          paymentInfo.returnUrl +
          "&paymentToken=" +
          component.state.paymentTokenGlobal,
        cancelUrl:
          ppsUrl +
          "/?action=processPaypal&returnUrl=" +
          paymentInfo.returnUrl +
          "&cancel=true&paymentToken=" +
          component.state.paymentTokenGlobal,
      };
      component.initPayPal(paypalPayload);
    }
  }

  async initPayPal(paypalPayload) {
    var component = this;
    try {
      const response = await Api.initPayPalPayment(paypalPayload);
      console.log(response);
      component.setState({ paypalResp: response });
      component.state.appLogger.info("Paypal response: ", response);
      component.state.appLogger.info("Paypal token: " + response.paypalToken);
      window.location = `${window.REACT_APP_paypalUrl}${response.paypalToken}/checkout/login`;
    } catch (error) {
      console.log(error);
      component.state.appLogger.error(error);
      const errors = {
        errorTitle: "Payment Error",
        errorMessage:
          "Please call Customer Service at 866-630-9305 for information regarding this order. We apologize for any inconvenience.",
        errors: [],
      };
      component.setState({ error: errors, blockerNeeded: null });
    }
  }

  billingPageOnCancel(e) {
    window.location =
      this.state.paymentResp.returnUrl +
      "?token=" +
      this.state.paymentTokenGlobal +
      "&status=" +
      Cancel;
  }

  googleCallback(token) {
    //console.log("google recap token",token);
    this.setState({ googleResponse: token });
    this.submitToVantiv();
  }

  echeckBillingPageOnSubmit(e) {
    if (this.echeckFormFieldsValidation(e) === true) {
      this.processEcheck(e);
    }
  }

  processEcheck(e) {
    console.log("process echeck ", e);
    this.setState({ emailAddress: e.email });
    this.setState({ blockerNeeded: true });
    var stateValue = "";
    var stateField = document.getElementById("state");
    if (stateField === undefined || stateField === null) {
      stateValue = "";
    } else {
      if (stateField.value.length > 2) {
        stateValue = "";
      } else {
        stateValue = stateField.value;
      }
    }

    var formatPhoneNumber;
    if (e.companyPhoneNumber != null || e.companyPhoneNumber !== undefined) {
      formatPhoneNumber = e.companyPhoneNumber.replace(/[^\d]/g, "");
    }

    let lastFour = e.accountNumber.substr(e.accountNumber.length - 4);
    let payLoad = {
      paymentToken: this.state.paymentTokenGlobal,
      methodOfPayment: "EC",
      lastFour: lastFour,
      billingInfo: {
        address: {
          street: e.address1,
          street2: e.address2,
          city: e.city,
          state: stateValue,
          postalCode: e.zipCode,
          country: e.country,
        },
        contact: {
          firstName: e.firstName,
          lastName: e.lastName,
          daytimePhone: formatPhoneNumber,
          companyName: e.companyName,
          email: e.email,
        },
      },
      echeck: {
        accountNumber: e.accountNumber,
        routingNumber: e.routingNumber,
        bankAccountType: e.accountType,
      },
    };

    this.state.appLogger.info("post to payment payload: ", payLoad);
    this.ecomProcessACHPayment(payLoad);
  }

  billingPageOnSubmit(e) {
    //get information back from object.
    //console.log("JJJJJ values: ", e);
    var cvvCheck = this.state.paymentResp.config.csvRequired;
    this.setState({ blockerNeeded: true });

    if (this.formFieldsValidation(e, cvvCheck) === true) {
      var googleReCap = this.state.paymentResp.config.useCaptcha;
      if (googleReCap) {
        var grecaptcha = window.grecaptcha;
        if (this.state.googleWidgetId === null) {
          var widgetId = grecaptcha.render("g-recaptcha", {
            sitekey: window.REACT_GCAP_Key,
            callback: this.googleCallback,
          });
          this.setState({ googleWidgetId: widgetId });
        }
        grecaptcha.reset(widgetId);
        grecaptcha.execute();
      } else {
        this.submitToVantiv();
      }
    }
  }

  async getPaymentConfig() {
    const component = this;
    const paymentToken = this.state.paymentTokenGlobal;
    component.state.appLogger.info("input payment token: " + paymentToken);

    try {
      this.setState({ blockerNeeded: true });
      let response = await Api.getPaymentConfig(paymentToken);
      console.log(response);
      component.setState({ paymentResp: response });
      component.setState({ paymentResp: response });
      component.state.appLogger.info(
        "paymentInfo: " + JSON.stringify(component.state.paymentResp)
      );
      this.setState({ blockerNeeded: null });
      defaultCountry = response.billingInfo.address.country;
      defaultState = response.billingInfo.address.state;
      let country =
        CountryMap[
          findIndex(CountryMap, (c) => {
            return c.abbr === defaultCountry;
          })
        ];
      if (country === undefined) {
        defaultCountry = "US";
        defaultState = "";
        if (response.billingInfo.address.country === "GU") {
          defaultCountry = "US";
          defaultState = "GU";
        }
        if (response.billingInfo.address.country === "PR") {
          defaultCountry = "US";
          defaultState = "PR";
        }
        if (response.billingInfo.address.country === "UM") {
          defaultCountry = "US";
          defaultState = "UM";
        }
        if (response.billingInfo.address.country === "VI") {
          defaultCountry = "US";
          defaultState = "VI";
        }
      }

      var paymentMethod = component.state.paymentResp.config.methodsOfPayment;
      var i = paymentMethod.length;
      var hasPayPal = false;
      var hasACH = false;
      while (i--) {
        if (paymentMethod[i] === "PP") {
          hasPayPal = true;
        }
        if (paymentMethod[i] === "EC") {
          hasACH = true;
        }
      }
      if (hasACH) {
        component.setState({ page: "select-ach" });
      } else {
        if (hasPayPal) {
          component.setState({ page: "select" });
        } else {
          component.setState({ page: "billing" });
        }
      }

      let userUrlElement = document.createElement("meta");
      userUrlElement.setAttribute(
        "content",
        "600;URL=/?action=sessionTimeout&returnUrl=" +
          component.state.paymentResp.returnUrl +
          "&token=" +
          paymentToken
      );
      userUrlElement.setAttribute("http-equiv", "Refresh");
      document.head.appendChild(userUrlElement);
    } catch (error) {
      console.log("error: ", error);
      const status = error.response?.data?.statusCode || error.status;
      let errorMessage =
        "We're sorry, but we were unable to process your request. Please try again. If this problem persists, please call (800) 323-7155 or 866-630-9305 to place an order with one of our customer service representatives.";
      if (status === 400) {
        const returnUrl = error.response?.data?.returnUrl;
        component.state.appLogger.info("returnUrl ", returnUrl);

        if (returnUrl) {
          window.location = `${returnUrl}?status=${TokenInvalid}`;
        } else {
          component.state.appLogger.error(
            "status code: " + status + " error message: " + error.message
          );
        }
      }

      const errors = { errorTitle: "Make a Payment", errorMessage, errors: [] };
      component.setState({ error: errors, blockerNeeded: null });
    }
  }

  onTimeoutAfterLitle() {
    //var elapsedTime = new Date().getTime() - this.state.elapsedTime;
    var errors = {
      errorTitle: "Payment Error",
      errorMessage:
        "Please call Customer Service at 866-630-9305 for information regarding this order. We apologize for any inconvenience.",
      errors: [],
    };
    this.setState({ error: errors });
    this.setState({ blockerNeeded: null });
  }

  submitAfterLitle(response) {
    //console.log("response from vantiv: "+ response);
    //this.state.appLogger.info("response from vantiv: " + response.paypageRegistrationId);
    var expDate =
      document.getElementById("expireMonth").value +
      document.getElementById("expireYear").value;

    var reCapRequired = this.state.paymentResp.config.useCaptcha;

    var stateValue = "";
    var stateField = document.getElementById("state");
    if (stateField === undefined || stateField === null) {
      stateValue = "";
    } else {
      if (stateField.value.length > 2) {
        stateValue = "";
      } else {
        stateValue = stateField.value;
      }
    }

    let emailAddress = document.getElementById("email").value;
    if (emailAddress) {
      emailAddress = emailAddress.trim();
    }
    this.setState({ emailAddress: emailAddress });
    let payLoad = {
      paymentToken: this.state.paymentTokenGlobal,
      payPageRegId: response.paypageRegistrationId,
      methodOfPayment: response.type,
      lastFour: response.lastFour,
      expDate: expDate,
      billingInfo: {
        address: {
          street: document.getElementById("address1").value,
          street2: document.getElementById("address2").value,
          city: document.getElementById("city").value,
          state: stateValue,
          postalCode: document.getElementById("zipCode").value,
          country: document.getElementById("country").value,
        },
        contact: {
          firstName: document.getElementById("firstName").value,
          lastName: document.getElementById("lastName").value,
          email: emailAddress,
        },
      },
      transactionId: response.litleTxnId,
    };

    if (reCapRequired) {
      payLoad.userCaptchaResponse = this.state.googleResponse;
    }
    this.state.appLogger.info("post to payment payload: ", payLoad);
    this.ecomProcessPayment(payLoad);
  }

  async ecomProcessACHPayment(payLoad) {
    let component = this;
    try {
      let response = await Api.processPayment(payLoad);
      component.state.appLogger.info("auth post response: ", response);
      component.state.appLogger.info(
        "rejectCounter before Auth: " + component.state.rejectCounter
      );
      var redirectSuccessUrl =
        component.state.paymentResp.returnUrl +
        "?token=" +
        component.state.paymentTokenGlobal +
        "&status=" +
        Successful;
      component.state.appLogger.info(
        "redirect successful " + redirectSuccessUrl
      );
      window.location = redirectSuccessUrl;
    } catch (error) {
      console.log(error);
      console.log(error.response.data.errorCode);
      console.log(error.response.data.statusCode);

      let errorCode = error.response.data.errorCode;
      let status = error.response.data.statusCode;

      console.log("errorCode", errorCode);
      console.log("status: ", status);

      if (status === 400) {
        component.state.appLogger.info("errorCode: " + errorCode);

        if (errorCode === 111) {
          var errors = {
            errorTitle: "Payment Error",
            errorMessage:
              "Please call Customer Service at 866-630-9305 for information regarding this order. We apologize for any inconvenience.",
            errors: [],
          };
          component.setState({ error: errors });
          component.setState({ blockerNeeded: null });
        } else {
          component.state.rejectCounter++;
          if (component.state.rejectCounter === config.MAX_ATTEMPTS) {
            var redirectRejectUrl =
              component.state.paymentResp.returnUrl +
              "?token=" +
              component.state.paymentTokenGlobal +
              "&status=" +
              Reject;
            component.state.appLogger.info(
              "redirect reject back: " + redirectRejectUrl
            );
            window.location = redirectRejectUrl;
          }

          var errors2 = {
            errorTitle: "Payment Error",
            errorMessage:
              "Your ACH payment could not be processed. Please verify your ACH Payment information and try again with a different bank account",
            errors: [],
          };
          component.setState({ error: errors2 });
          component.setState({ blockerNeeded: null });
        }
      } else {
        var errors3 = {
          errorTitle: "Payment Error",
          errorMessage:
            "Please call Customer Service at 866-630-9305 for information regarding this order. We apologize for any inconvenience.",
          errors: [],
        };
        component.setState({ error: errors3 });
        component.setState({ blockerNeeded: null });
      }
    }
  }

  async ecomProcessPayment(payLoad) {
    let component = this;
    try {
      const response = await Api.processPayment(payLoad);
      console.log(response);
      component.state.appLogger.info("auth post response: ", response);
      component.state.appLogger.info(
        "rejectCounter before Auth: " + component.state.rejectCounter
      );
      var redirectSuccessUrl =
        component.state.paymentResp.returnUrl +
        "?token=" +
        component.state.paymentTokenGlobal +
        "&status=" +
        Successful;
      component.state.appLogger.info(
        "redirect successful " + redirectSuccessUrl
      );
      window.location = redirectSuccessUrl;
    } catch (error) {
      console.log(error);
      console.log(error?.response?.data?.errorCode);
      console.log(error?.response?.data?.statusCode);

      let errorCode = error?.response?.data?.errorCode;
      let status = error?.response?.data?.statusCode;

      console.log("errorCode", errorCode);
      console.log("status: ", status);

      if (status === 400) {
        component.state.appLogger.info("errorCode: " + errorCode);

        if (errorCode === 111) {
          var errors = {
            errorTitle: "Payment Error",
            errorMessage:
              "Please call Customer Service at 866-630-9305 for information regarding this order. We apologize for any inconvenience.",
            errors: [],
          };
          component.setState({ error: errors });
          component.setState({ blockerNeeded: null });
        } else {
          if (errorCode === "invalidInput") {
            var errors = {
              errorTitle: "Payment Error",
              errorMessage: "Please Check Invalid Input and try again.",
              errors: [],
            };
            component.setState({ error: errors });
            component.setState({ blockerNeeded: null });
          } else {
            if (errorCode === 120) {
              //googleCap failed.
              var finalErrors = [];
              var error1 = {
                id: "googleCap",
                error: "Google reCAPTCHA Response can not be verified",
              };
              finalErrors.push(error1);

              var errors1 = {
                errorTitle: "Errors",
                errorMessage: "Please review these errors",
                errors: [],
              };
              component.setState({ error: errors1 });
              component.setState({ blockerNeeded: null });

              var grecaptcha = window.grecaptcha;
              grecaptcha.reset(component.state.googleWidgetId);

              component.state.rejectCounter++;
              if (component.state.rejectCounter === config.MAX_ATTEMPTS) {
                var redirectRejectUrl =
                  component.state.paymentResp.returnUrl +
                  "?token=" +
                  component.state.paymentTokenGlobal +
                  "&status=" +
                  Reject;
                component.state.appLogger.info(
                  "redirect reject back: " + redirectRejectUrl
                );
                window.location = redirectRejectUrl;
              }
            } else {
              component.state.rejectCounter++;
              if (component.state.rejectCounter === config.MAX_ATTEMPTS) {
                var redirectRejectUrl2 =
                  component.state.paymentResp.returnUrl +
                  "?token=" +
                  component.state.paymentTokenGlobal +
                  "&status=" +
                  Reject;
                component.state.appLogger.info(
                  "redirect reject back: " + redirectRejectUrl2
                );
                window.location = redirectRejectUrl2;
              }

              var errors2 = {
                errorTitle: "Payment Error",
                errorMessage:
                  "Your credit card could not be processed. Please verify your credit card information and try again or try a different credit card.",
                errors: [],
              };
              component.setState({ error: errors2 });
              component.setState({ blockerNeeded: null });
            }
          }
        }
      } else {
        var errors3 = {
          errorTitle: "Payment Error",
          errorMessage:
            "Please call Customer Service at 866-630-9305 for information regarding this order. We apologize for any inconvenience.",
          errors: [],
        };
        component.setState({ error: errors3 });
        component.setState({ blockerNeeded: null });
      }
    }
  }

  onErrorAfterLitle(response) {
    this.state.appLogger.error("error code: " + JSON.stringify(response));
    this.state.appLogger.error("error message: " + response.message);
    this.setState({ blockerNeeded: null });

    if (
      response.response === "889" ||
      response.response === "875" ||
      response.response === null
    ) {
      var errors = {
        errorTitle: "Payment Error",
        errorMessage:
          "Please Call Customer Service At 866-630-9305 For Information Regarding This Order. We apologize for any inconvenience.",
        errors: [],
      };
      this.setState({ error: errors });
    } else {
      if (response.message) {
        var errors4 = {
          errorTitle: "Payment Error",
          errorMessage:
            "Your Credit Card Could Not Be Processed. Please verify your credit card information and try again or try a different credit card.",
          errors: [],
        };
        this.setState({ error: errors4 });
      }

      //this.state.rejectCounter++;
      let nCount = this.state.rejectCounter + 1;
      this.setState({ rejectCounter: nCount });
      this.state.appLogger.info("reject counter: " + this.state.rejectCounter);
      if (this.state.rejectCounter === config.MAX_ATTEMPTS) {
        var redirectUrl =
          this.state.paymentResp.returnUrl +
          "?token=" +
          this.state.paymentTokenGlobal +
          "&status=" +
          Reject;
        this.state.appLogger.info("redirect back: " + redirectUrl);
        window.location =
          this.state.paymentResp.returnUrl +
          "?token=" +
          this.state.paymentTokenGlobal +
          "&status=" +
          Reject;
      }
    }
  }

  submitToVantiv() {
    var cvvRequired = this.state.paymentResp.config.csvRequired;
    let randomStr = Math.random().toString(36).substring(2, 12);
    let reqId = this.state.paymentTokenGlobal.substr(0, 14) + randomStr;
    console.log("reqId: ", reqId);
    console.log("reqId length ", reqId.length);

    if (cvvRequired === true) {
      var formFields = {
        accountNum: document.getElementById("creditCardNumber"),
        paypageRegistrationId: this.state.paymentResp.config.payPageId,
        cvv: document.getElementById("securityCode"),
      };

      this.setState({ elapsedTime: new Date().getTime() });

      var litleRequest = {
        paypageId: this.state.paymentResp.config.payPageId,
        reportGroup: "ECM",
        orderId: this.state.paymentTokenGlobal.substring(0, 24),
        id: reqId,
        cvv: document.getElementById("securityCode"),
        url: window.REACT_APP_vantivUrl,
      };
    } else {
      //no cvv.
      formFields = {
        accountNum: document.getElementById("creditCardNumber"),
        paypageRegistrationId: this.state.paymentResp.config.payPageId,
      };

      this.setState({ elapsedTime: new Date().getTime() });

      litleRequest = {
        paypageId: this.state.paymentResp.config.payPageId,
        reportGroup: "ECM",
        orderId: this.state.paymentTokenGlobal.substring(0, 24),
        id: reqId,
        url: window.REACT_APP_vantivUrl,
      };
    }

    if (window.eProtect === undefined) {
      console.log("eProtect is not defined");
      this.setState({ blockerNeeded: null });
      var errors = {
        errorTitle: "Payment Error",
        errorMessage:
          "If you are experiencing any issues with processing your payment, please contact your IT team to make sure you can access the below sites: https://request.eprotect.vantivcnp.com for our third-party payment processor. However, if you are still experiencing issues, please contact Customer Service at 866-630-9305. We apologize for any inconvenience this may have caused.",
        errors: [],
      };
      this.setState({
        error: errors,
      });
      return;
    }
    new window.eProtect().sendToEprotect(
      litleRequest,
      formFields,
      this.submitAfterLitle,
      this.onErrorAfterLitle,
      this.onTimeoutAfterLitle,
      config.VANTIVE_TIMEOUT
    );
  }

  echeckFormFieldsValidation(eventValue) {
    var firstName = eventValue.firstName;
    var lastName = eventValue.lastName;
    var street1 = eventValue.address1;
    var street2 = eventValue.address2;

    var city = eventValue.city;
    var country = eventValue.country;
    //var state = eventValue.state;
    var zip = eventValue.zipCode;
    var email = eventValue.email;

    var nameValidator = new RegExp("^[0-9a-zA-Z ,-. ]+$");
    var streetValidator = new RegExp("^[a-zA-Z0-9 #@:&'-./]+$");
    var cityValidator = new RegExp("^[a-zA-Z0-9 #&'-./]+$");
    //var zipValidator = new RegExp()
    var firstNamePass = nameValidator.test(firstName);
    var lastNamePass = nameValidator.test(lastName);
    var street1Pass = streetValidator.test(street1);
    var street2Pass = streetValidator.test(street2);

    var cityPass = cityValidator.test(city);
    var zipPass = this.isValidPostalCode(zip, country);
    var emailPass = this.isEmailValidate(email);

    //build error array.
    var finalErrors = [];

    if (!emailPass) {
      var error = { id: "email", error: "Email Address is invalid" };
      finalErrors.push(error);
    }

    if (
      eventValue.companyPhoneNumber != null ||
      eventValue.companyPhoneNumber !== undefined
    ) {
      let formatPhoneNumber = eventValue.companyPhoneNumber.replace(
        /[^\d]/g,
        ""
      );
      if (formatPhoneNumber.length !== 10) {
        var error = {
          id: "companyPhoneNumber",
          error: "Company Phone Number is invalid",
        };
        finalErrors.push(error);
      }
    }

    if (
      eventValue.routingNumber.length < 8 ||
      eventValue.routingNumber.length > 9
    ) {
      var error1 = { id: "routingNumber", error: "Routing Number is invalid" };
      finalErrors.push(error1);
    }
    if (
      eventValue.accountNumber.length < 5 ||
      eventValue.accountNumber.length > 17
    ) {
      var error2 = { id: "accountNumber", error: "Account Number is invalid" };
      finalErrors.push(error2);
    }

    if (!firstNamePass) {
      var error3 = { id: "firstName", error: "First Name is invalid" };
      finalErrors.push(error3);
    } else {
      //check length here.
      if (firstName.length > 15) {
        error = { id: "firstName", error: "First Name is too long" };
        finalErrors.push(error);
      }
    }

    if (!lastNamePass) {
      error = { id: "lastName", error: "Last Name is invalid" };
      finalErrors.push(error);
    } else {
      //check length here.
      if (lastName.length > 15) {
        error = { id: "lastName", error: "Last Name is too long" };
        finalErrors.push(error);
      }
    }
    if (!street1Pass) {
      error = { id: "address1", error: "Address1 is invalid" };
      finalErrors.push(error);
    } else {
      //check length here.
      if (street1.length > 30) {
        error = { id: "address1", error: "Address1 is too long" };
        finalErrors.push(error);
      }
    }
    if (street2 !== "" && street2 !== undefined) {
      street2Pass = streetValidator.test(street2);
      if (!street2Pass) {
        error = { id: "address2", error: "Address2 is invalid" };
        finalErrors.push(error);
      } else {
        //check length here.
        if (street2.length > 30) {
          error = { id: "address2", error: "Address2 is too long" };
          finalErrors.push(error);
        }
      }
    }

    if (!cityPass) {
      error = { id: "city", error: "City is invalid" };
      finalErrors.push(error);
    } else {
      //check length here.
      if (city.length > 35) {
        error = { id: "city", error: "City length is too long" };
        finalErrors.push(error);
      }
    }

    if (!zipPass) {
      error = { id: "zipCode", error: "Zip/Postal Code is invalid" };
      finalErrors.push(error);
    }

    if (finalErrors.length > 0) {
      console.log("form validation errors: ", finalErrors);
      var errors = {
        timestamp: new Date().getTime(),
        errorTitle: "Errors",
        errorMessage: "Please review these errors",
        errors: finalErrors,
      };
      this.setState({ error: errors });
      this.setState({ blockerNeeded: null });
      return false;
    }

    return true;
  }

  formFieldsValidation(eventValue, cvvCheck) {
    var firstName = eventValue.firstName;
    var lastName = eventValue.lastName;
    var street1 = eventValue.address1;
    var street2 = eventValue.address2;

    var city = eventValue.city;
    var country = eventValue.country;
    //var state = eventValue.state;
    var zip = eventValue.zipCode;

    var ccValue = eventValue.creditCardNumber;
    var expirMonth = eventValue.expireMonth;
    var expirYear = eventValue.expireYear;
    var ccType = eventValue.cards;
    var securityCode = eventValue.securityCode;

    var email = eventValue.email;

    var nameValidator = new RegExp("^[0-9a-zA-Z ,-. ]+$");
    var streetValidator = new RegExp("^[a-zA-Z0-9 #@:&'-./]+$");
    var cityValidator = new RegExp("^[a-zA-Z0-9 #&'-./]+$");
    //var zipValidator = new RegExp()
    var firstNamePass = nameValidator.test(firstName);
    var lastNamePass = nameValidator.test(lastName);
    var street1Pass = streetValidator.test(street1);
    var street2Pass = streetValidator.test(street2);

    var cityPass = cityValidator.test(city);
    var zipPass = this.isValidPostalCode(zip, country);
    var ccPass = this.isValidCreditCard(ccValue);
    var expPass = this.isValidExpMonthYear(expirMonth, expirYear);

    var emailPass = this.isEmailValidate(email);

    //build error array.
    var finalErrors = [];

    if (!emailPass) {
      var error = { id: "email", error: "Email Address is invalid" };
      finalErrors.push(error);
    }

    if (!ccType || ccType === "none") {
      var error = { id: "cards", error: "Card Type is invalid" };
      finalErrors.push(error);
    }

    if (!firstNamePass) {
      var error = {
        id: "firstName",
        error: "First Name is invalid, only English Characters are accepted.",
      };
      finalErrors.push(error);
    } else {
      //check length here.
      if (firstName.length > 15) {
        error = { id: "firstName", error: "First Name is too long" };
        finalErrors.push(error);
      }
    }

    if (!lastNamePass) {
      error = {
        id: "lastName",
        error: "Last Name is invalid, only English Characters are accepted.",
      };
      finalErrors.push(error);
    } else {
      //check length here.
      if (lastName.length > 15) {
        error = { id: "lastName", error: "Last Name is too long" };
        finalErrors.push(error);
      }
    }
    if (!street1Pass) {
      error = {
        id: "address1",
        error: "Address1 is invalid, only English Characters are accepted.",
      };
      finalErrors.push(error);
    } else {
      //check length here.
      if (street1.length > 30) {
        error = { id: "address1", error: "Address1 is too long" };
        finalErrors.push(error);
      }
    }
    if (street2 !== "" && street2 !== undefined) {
      street2Pass = streetValidator.test(street2);
      if (!street2Pass) {
        error = {
          id: "address2",
          error: "Address2 is invalid, only English Characters are accepted.",
        };
        finalErrors.push(error);
      } else {
        //check length here.
        if (street2.length > 30) {
          error = { id: "address2", error: "Address2 is too long" };
          finalErrors.push(error);
        }
      }
    }

    if (!cityPass) {
      error = {
        id: "city",
        error: "City is invalid, only English Characters are accepted.",
      };
      finalErrors.push(error);
    } else {
      //check length here.
      if (city.length > 35) {
        error = { id: "city", error: "City length is too long" };
        finalErrors.push(error);
      }
    }

    if (!zipPass) {
      error = { id: "zipCode", error: "Zip/Postal Code is invalid" };
      finalErrors.push(error);
    }

    if (!ccPass) {
      error = {
        id: "creditCardNumber",
        error: "Credit Card Number is invalid",
      };
      finalErrors.push(error);
    }

    if (!expirMonth || expirMonth === "none") {
      error = { id: "expireMonth", error: "Expire Month is invalid" };
      finalErrors.push(error);
    }

    if (!expirYear || expirYear === "none") {
      error = { id: "expireYear", error: "Expire Year is invalid" };
      finalErrors.push(error);
    }

    if (!expPass && expirMonth && expirYear) {
      error = { id: "expireMonth", error: "Expire Month or Year is invalid" };
      finalErrors.push(error);
    }

    if (cvvCheck) {
      var cvvValidator = new RegExp("[0-9]");

      if (!cvvValidator.test(securityCode)) {
        error = { id: "securityCode", error: "Security Code is invalid" };
        finalErrors.push(error);
      }

      var cvvPass = this.isValidCvvLength(ccType, securityCode);
      if (!cvvPass) {
        error = {
          id: "securityCode",
          error: "Security Code length is not correct",
        };
        finalErrors.push(error);
      }
    }

    if (finalErrors.length > 0) {
      /*
            window.ApplicationUtility.renderErrors({
                errorTitle: 'Errors',
                errorMessage: 'Please review these errors',
                errors: finalErrors
            });*/
      console.log("form validation errors: ", finalErrors);
      var errors = {
        timestamp: new Date().getTime(),
        errorTitle: "Errors",
        errorMessage: "Please review these errors",
        errors: finalErrors,
      };
      this.setState({ error: errors });
      this.setState({ blockerNeeded: null });
      return false;
    }

    return true;
  }

  isValidPostalCode(postalCode, countryCode) {
    var postalCodeRegex = new RegExp("^[ A-Za-z0-9 -]*$");
    switch (countryCode) {
      case "US":
        postalCodeRegex = new RegExp("^([0-9]{5})(?:[-\\s]*([0-9]{4}))?$");
        break;
      case "CA":
        return postalCodeRegex.test(postalCode);

      default:
        return postalCodeRegex.test(postalCode);
    }
    return postalCodeRegex.test(postalCode);
  }

  isEmailValidate(value) {
    let test = new RegExp(/^.+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
    return test.test(value);
  }

  isValidExpMonthYear(month, year) {
    var d = new Date();
    var currentYear = d.getFullYear();
    var currentMonth = d.getMonth() + 1;
    var inputYear = parseInt("20" + year, 10);
    var inputMonth = parseInt(month);
    // compare the dates
    if (
      inputYear < currentYear ||
      (inputYear === currentYear && inputMonth < currentMonth)
    ) {
      return false;
    }

    return true;
  }

  isValidCreditCard(value) {
    // accept only digits, dashes or spaces
    if (value === null || value === "") return false;
    if (/[^0-9-\s]+/.test(value)) return false;

    // The Luhn Algorithm. It's so pretty.
    var nCheck = 0,
      nDigit = 0,
      bEven = false;
    value = value.replace(/\D/g, "");

    for (var n = value.length - 1; n >= 0; n--) {
      var cDigit = value.charAt(n);
      nDigit = parseInt(cDigit, 10);

      if (bEven) {
        if ((nDigit *= 2) > 9) nDigit -= 9;
      }

      nCheck += nDigit;
      bEven = !bEven;
    }

    return nCheck % 10 === 0;
  }

  isValidCvvLength(cardType, cvv) {
    if (
      cardType === "VI" ||
      cardType === "MC" ||
      cardType === "DI" ||
      cardType === "JC"
    ) {
      return cvv.length === 3;
    }
    if (cardType === "AX") {
      return cvv.length === 4;
    }
  }

  render() {
    return (
      <>
        <cb.widgets.GlobalHeader
          appId={408}
          useGlobalNavigation={false}
          useLoginWidget={false}
          useSearchWidget={false}
        />
        {this.state.page &&
          this.state.page === "select" &&
          this.state.paymentResp && (
            <PPSSelect
              cardMap={getCreditCardsByAbbr(
                this.state.paymentResp.config.methodsOfPayment
              )}
              defaultSelection="Cards"
              appId={this.state.paymentResp.config.appSource}
              flowCode={this.state.pagenameFlow}
              onSubmit={(value) => {
                console.log(value);
                this.selectPageOnSubmit(value);
              }}
              onCancel={() => {
                console.log("On Cancel");
                this.selectPageOnCancel();
              }}
            />
          )}

        {this.state.page &&
          this.state.page === "select-ach" &&
          this.state.paymentResp && (
            <PPSSelectACH
              cardMap={getCreditCardsByAbbr(
                this.state.paymentResp.config.methodsOfPayment
              )}
              defaultSelection="echeck"
              appId={this.state.paymentResp.config.appSource}
              flowCode={this.state.pagenameFlow}
              onSubmit={(value) => {
                console.log(value);
                this.selectPageOnSubmit(value);
              }}
              onCancel={() => {
                console.log("On Cancel");
                this.selectPageOnCancel();
              }}
            />
          )}

        {this.state.page && this.state.page === "billing" && (
          <PPSBilling
            defaults={{
              firstName: this.state.paymentResp.billingInfo.contact.firstName,
              lastName: this.state.paymentResp.billingInfo.contact.lastName,
              address1: this.state.paymentResp.billingInfo.address.street,
              address2: this.state.paymentResp.billingInfo.address.street2,
              city: this.state.paymentResp.billingInfo.address.city,
              state: defaultState,
              zipCode: this.state.paymentResp.billingInfo.address.postalCode,
              country: defaultCountry,
              email: this.state.paymentResp.billingInfo.contact.email,
            }}
            amount={this.state.paymentResp.amount.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
            onSubmit={(e) => {
              console.log("On Submit", e);
              this.billingPageOnSubmit(e);
            }}
            onCancel={(e) => {
              console.log("On Cancel", e);
              this.billingPageOnCancel(e);
            }}
            errorInfo={this.state.error}
            appId={this.state.paymentResp.config.appSource}
            flowCode={this.state.pagenameFlow}
            ccvRequired={this.state.paymentResp.config.csvRequired}
            emailRequired={true}
          />
        )}

        {this.state.page &&
          this.state.page === "billing-ach" &&
          this.state.defaultEmptyAddress === null && (
            <PPSBillingACH
              defaults={{
                firstName: this.state.paymentResp.billingInfo.contact.firstName,
                lastName: this.state.paymentResp.billingInfo.contact.lastName,
                address1: this.state.paymentResp.billingInfo.address.street,
                address2: this.state.paymentResp.billingInfo.address.street2,
                city: this.state.paymentResp.billingInfo.address.city,
                state: this.state.paymentResp.billingInfo.address.state,
                zipCode: this.state.paymentResp.billingInfo.address.postalCode,
                country: this.state.paymentResp.billingInfo.address.country,
                email: this.state.paymentResp.billingInfo.contact.email,
                companyPhoneNumber:
                  this.state.paymentResp.billingInfo.contact.phone,
                accountType: "CORPORATE_CHECKING",
              }}
              amount={this.state.paymentResp.amount.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
              onSubmit={(e) => {
                console.log("On Submit", e);
                this.echeckBillingPageOnSubmit(e);
              }}
              onCancel={(e) => {
                console.log("On Cancel", e);
                this.billingPageOnCancel(e);
              }}
              errorInfo={this.state.error}
              appId={this.state.paymentResp.config.appSource}
              flowCode={this.state.pagenameFlow}
              emailRequired={true}
            />
          )}

        {this.state.page &&
          this.state.page === "billing-ach" &&
          this.state.defaultEmptyAddress !== null && (
            <PPSBillingACH
              defaults={{
                firstName: this.state.paymentResp.billingInfo.contact.firstName,
                lastName: this.state.paymentResp.billingInfo.contact.lastName,
                address1: "",
                address2: "",
                city: "",
                state: "",
                zipCode: "",
                country: "US",
                accountType: "CORPORATE_CHECKING",
                companyPhoneNumber:
                  this.state.paymentResp.billingInfo.contact.phone,
                email: this.state.paymentResp.billingInfo.contact.email,
              }}
              amount={this.state.paymentResp.amount.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
              onSubmit={(e) => {
                console.log("On Submit", e);
                this.echeckBillingPageOnSubmit(e);
              }}
              onCancel={(e) => {
                console.log("On Cancel", e);
                this.billingPageOnCancel(e);
              }}
              errorInfo={this.state.error}
              appId={this.state.paymentResp.config.appSource}
              flowCode={this.state.pagenameFlow}
              emailRequired={true}
            />
          )}

        {this.state.sessionTimeOutUrl && (
          <PPSError
            errorInfo={{
              errorTitle: "Session has timed out",
              errorMessage: null,
            }}
          >
            <p>
              We're sorry, but your session has timed out due to inactivity.
              Please click the &quot;Go Back&quot; button below to return.
              Clicking the &quot;Back&quot; button in your browser will not
              allow you to complete your order. Thank you.
              <br />
              <br />
              <a
                className="btn btn-primary btn-sm"
                href={
                  this.state.sessionTimeOutUrl +
                  "?token=" +
                  this.state.paymentTokenGlobal +
                  "&status=9999"
                }
              >
                <strong>
                  <u>Go Back</u>
                </strong>
              </a>
            </p>
          </PPSError>
        )}

        {this.state.error && !this.state.page && (
          <PPSError errorInfo={this.state.error} />
        )}

        {this.state.blockerNeeded && <ApricotLoader />}

        <cb.widgets.GlobalFooter
          appId={408}
          showLinks={false}
          showBackToTopLink={false}
        />
      </>
    );
  }
}

export default Home;
